import React , {useEffect} from "react";
import { useState } from 'react';
import axios from 'axios';
import { RiseLoader } from 'react-spinners'
import { useToast } from "@chakra-ui/react";
import { useStyles } from "../style";
import { FeedbackRepository } from "../repositories/feedback_repository";
import {useTranslation} from 'gatsby-plugin-react-i18next';
 
type IProps = {
  rating: number;
  type?: string;
  response?: any;
  handleReFeedBack?:any,
  soNumState?:any ,
  phoneNumState?:any;
};

const getTitle = (rating: number) => {
  switch (rating) {
    case 4:
      return "PNJ chân thành cảm ơn Quý khách đã dành thời gian để đánh giá chất lượng sản phẩm/dịch vụ tại PNJ";
    case 5:
      return "PNJ chân thành cảm ơn Quý khách đã dành thời gian để đánh giá chất lượng sản phẩm/dịch vụ tại PNJ.";
    case 1:
    case 2:
    case 3:
    default:
      return "PNJ xin lỗi về bất tiện quý khách đã gặp phải!";
  }
};

const getText = (rating: number) => {
  switch (rating) {
    case 4:
      return "Mọi ý kiến đóng góp từ Quý khách PNJ luôn trân trọng và ghi nhận để cải thiện.";
    case 5:
      return "Sự hài lòng của quý khách là động lực để PNJ duy trì và cải tiến chất lượng sản phẩm/dịch vụ.";
    case 1:
    case 2:
    case 3:
    default:
      return "PNJ chân thành cảm ơn Quý khách đã dành thời gian để đánh giá chất lượng sản phẩm/dịch vụ tại PNJ. Mọi ý kiến đóng góp từ Quý khách PNJ luôn trân trọng và ghi nhận để cải thiện.";
  }
};

export const ThankYouComponent = ({ rating, type, response  ,soNumState , phoneNumState , handleReFeedBack }: IProps) => {
  const [phoneNum, setPhoneNum] = useState<string>('');
  const [loading, setLoading] = useState<any>(false)
  const [isSuccess, setIsSuccess] = useState<any>(false)
  const [reFeedback ,setReFeedback] = useState<boolean>(false)

  const toast = useToast();
  const classes = useStyles();
  const { t } = useTranslation();
  const phone = sessionStorage.getItem("phone");

  const handleSetPhoneNum = (e: any) => {
    setPhoneNum(e.target.value)
  }

  const checkFeedBack = async () => {
    const validateData = await FeedbackRepository.validate(
      soNumState,
      phoneNumState
    );
    return validateData

  }
  useEffect( () => {
    if(soNumState && phoneNumState) {
      checkFeedBack()
      .then(result => {
         if(result?.data?.valid_feedback_again == true) {
           setReFeedback(!reFeedback)
         }
      })
    }
    
     
 
   } , [soNumState , phoneNumState])
   useEffect(() => {
    if(phone) {
      setIsSuccess(true)
    }
   } , [])
  const showErrorMsg = async (msg: string): Promise<void> => {
    toast({
      title: msg || ``,
      status: `error`,
      duration: 2000,
      isClosable: false,
    });
  };
   console.log(response , 'response')
  const handleSubmit = () => {
    const payload = {
      phoneNum: phoneNum,
      id: response.data.data.id,
      ref_id:response.data.data.ref_id
    }
    const regexPhoneNumber = /(84|0[3|5|7|8|9])+([0-9]{8})\b/g;

    if (!payload.phoneNum.match(regexPhoneNumber)) {
      showErrorMsg('Số điện thoại chưa hợp lệ!');
      return;
    }
    setLoading(true);
    axios.post(process.env.GATSBY_END_POINT + '/ecom-frontend/v1/create-store-review', payload)
      .then((result) => {
        if (result.status == 200) {
          setLoading(false);
          setIsSuccess(true)
          sessionStorage.setItem("phone", phoneNum);

        }
        else {
          setLoading(false);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  return (
    <div className={ `${type == 'store' ? 'container_store_ratings' : "body-3"}`}>
      <div className="container-3 w-container ">
        <img
          src="/images/diamond.png"
          loading="lazy"
          alt=""
          className="image"
        />
        {type == "store" ?
          <div>
            <p className="thank-subtitle-store" >{t("thank you")}​</p>
            <p className="thank-subtitle-store" >{t("In case you would like to share directly")}​​</p>
            
              {!isSuccess && <p className="thank-subtitle-store">{t("contact")}</p>}
            <div className="thankyou-action thankyou-action-mobile">
              <a href="tel:1800545457">{t("call button")}</a>
            </div>
            <div className="thankyou-action thankyou-action-desktop">
            <a>{t("call button")}</a>
            </div>
            {!isSuccess &&
       
              <div className="phoneNum">
                <input className="phoneNumInput" onChange={handleSetPhoneNum} type="text" placeholder={t("Enter phone number")} maxLength={11} />
              </div>
          
            }

            {phoneNum && !isSuccess &&
              <div onClick={handleSubmit} className="thankyou-action button">
                <a>{t("finish submit phone")}</a>
              </div>
            }
            {loading &&
              <div className={classes.overlayLoading}>
                <RiseLoader color="#fff" style={{ 'display': 'flex', 'justifyContent': 'center', 'alignItems': 'center', 'height': '100vh' }} />
              </div>
            }
           </div>
          :
          <div>
            <div className="thank-title">{getTitle(rating)}</div>
            <p className="thank-subtitle">{getText(rating)}</p>
            
            {rating <= 3 && (
              <div className="thankyou-action">
                <p className="thankyou-messages">PNJ sẽ nhanh chóng liên hệ đến Quý khách trong giờ làm việc để nhận chia sẻ và góp ý về sự bất tiện này</p>
              </div>
            )}
            <div className="thanhyou-action-container"  >
            {/* {reFeedback && <div onClick={handleReFeedBack}  className="re-feedback">Đánh giá lại</div>} */}
            <div className="thankyou-action thankyou-action-mobile">
              <a style={{"width":"100%"}} href="tel:1800545457">Gọi Hotline PNJ (Miễn phí)</a>
            </div>
            <div className="thankyou-action thankyou-action-desktop">
              <a style={{"width":"100%"}}>Liên hệ Hotline (Miễn phí): 1800545457 </a>
            </div>
            </div>
          </div>
        }
      </div>
    </div>
  );
};
